import React, { FC } from 'react'
import { Collapse as ANTDCollapse } from 'antd'
import { CollapsePanelProps, CollapseProps } from 'antd/lib/collapse'
import { CollapseStyle } from '../../../style/styled/antd/Collase.style'

export const Collapse: FC<CollapseProps> & {
  Panel: FC<CollapsePanelProps>
} = (props) => (
  <>
    <style jsx global>
      {CollapseStyle}
    </style>
    <ANTDCollapse {...props} />
  </>
)
Collapse.Panel = ANTDCollapse.Panel
