import React, { FC } from 'react'
import { Tag as ANTDTag } from 'antd'
import { TagProps } from 'antd/lib/tag'
import { TagStyle } from '../../../style/styled/antd/Tag.style'

export const Tag: FC<TagProps & React.RefAttributes<HTMLDivElement>> = (props) => (
  <>
    <style jsx global>
      {TagStyle}
    </style>
    <ANTDTag {...props} />
  </>
)
