import React, { FC } from 'react'
import { Divider as ANTDDivider } from 'antd'
import { DividerProps } from 'antd/lib/divider'
import { DividerStyle } from '../../../style/styled/antd/Divider.style'

export const Divider: FC<DividerProps> = (props) => (
  <>
    <style jsx global>
      {DividerStyle}
    </style>
    <ANTDDivider {...props} />
  </>
)
