import React, { FC } from 'react'
import { Menu as ANTDMenu } from 'antd'
import { MenuProps } from 'antd/lib/menu'
import { MenuStyle } from '../../../style/styled/antd/Menu.style'

export const Menu: FC<MenuProps> & { Item?: typeof ANTDMenu.Item } = (props) => (
  <>
    <style jsx global>
      {MenuStyle}
    </style>
    <ANTDMenu {...props} />
  </>
)

Menu.Item = ANTDMenu.Item
