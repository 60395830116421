import React, { FC } from 'react'
// eslint-disable-next-line no-restricted-imports
import { Dayjs } from 'dayjs'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import generatePicker, { PickerProps, RangePickerProps } from 'antd/lib/date-picker/generatePicker'
import { DatePickerStyle } from '../../../style/styled/antd/DatePicker.style'
// import 'antd/es/date-picker/style/index'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ANTDDatePicker = generatePicker<Dayjs>(dayjsGenerateConfig)

export const DatePickerFRef = React.forwardRef<any, PickerProps<Dayjs>>((props, ref) => (
  <>
    <style jsx global>
      {DatePickerStyle}
    </style>
    <ANTDDatePicker {...props} ref={ref} />
  </>
))

export const DatePicker: typeof DatePickerFRef & { RangePicker?: FC<RangePickerProps<Dayjs>>; ref?: any } = DatePickerFRef

DatePicker.RangePicker = (props) => (
  <>
    <style jsx global>
      {DatePickerStyle}
    </style>
    <ANTDDatePicker.RangePicker {...props} />
  </>
)
