import React, { FC } from 'react'
import { Drawer as ANTDDrawer } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import { DrawerStyle } from '../../../style/styled/antd/Drawer.style'

export const Drawer: FC<DrawerProps> = (props) => (
  <>
    <style jsx global>
      {DrawerStyle}
    </style>
    <ANTDDrawer {...props} />
  </>
)
