import React, { FC } from 'react'
// import { Avatar as ANTDAvatar } from 'antd'
import { AvatarProps } from 'antd/lib/avatar'
import { AvatarStyle } from '../../../style/styled/antd/Avatar.style'

export const Avatar: FC<AvatarProps & { title?: string }> = (props) => {
  const { shape } = props
  const [isImgExist, setIsImgExist] = React.useState(true)
  const handleImgLoadError = () => {
    const { onError } = props
    const errorFlag = onError ? onError() : undefined
    if (errorFlag !== false) {
      setIsImgExist(false)
    }
  }
  // const size = customSize === 'default' ? groupSize : customSize
  // const sizeCls = classNames({
  //   [`${prefixCls}-lg`]: size === 'large',
  //   [`${prefixCls}-sm`]: size === 'small'
  // })
  // const classString = classNames(
  //   prefixCls,
  //   sizeCls,
  //   {
  //     [`${prefixCls}-${shape}`]: !!shape,
  //     [`${prefixCls}-image`]: hasImageElement || (src && isImgExist),
  //     [`${prefixCls}-icon`]: !!icon
  //   },
  //   className
  // )
  return (
    <>
      <style jsx global>
        {AvatarStyle}
      </style>
      <span
        className={`ant-avatar ant-avatar-${shape} ${isImgExist && 'ant-avatar-image'} object-fit-contain undefined`}
        style={{ width: props.size, height: props.size, lineHeight: props.size }}>
        {props.src && isImgExist ? (
          <img src={props.src} onError={handleImgLoadError} alt={props.alt} title={props.title} />
        ) : (
          <span className="ant-avatar-string" style={{ transform: 'scale(1) translateX(-50%)', lineHeight: props.size }}></span>
        )}
      </span>
      {/* <ANTDAvatar {...props} /> */}
    </>
  )
}
